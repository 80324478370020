import React, { FC } from "react";
import styled, { css } from "styled-components";
import { easeIn, easeOut, easeOutFast } from "../styles/atoms";
import { RED } from "../styles/constants";
import { SIDE_SPACING } from "../styles/max-width";

interface Props extends BtnProps {
  id?: string;
  href?: string;
  onClick?: (e?: any) => void;
  type?: "button" | "reset" | "submit";
  bigger?: boolean;
  big?: boolean;
  disabled?: boolean;
  fullOnWidth?: number;
  loading?: boolean;
  insideLink?: boolean;
}

const Button: FC<Props> = ({
  children,
  full,
  type = "button",
  disabled,
  loading,
  insideLink,
  ...props
}) => {
  return insideLink ? (
    <BtnInsideLink
      type={type}
      full={full}
      disabled={disabled}
      isLoading={loading}
      {...props}
    >
      {children}
    </BtnInsideLink>
  ) : (
    <Btn
      type={type}
      full={full}
      disabled={disabled}
      isLoading={loading}
      {...props}
    >
      {children}
    </Btn>
  );
};

interface BtnProps {
  type?: string;
  disabled?: boolean;
  full?: boolean;
  bold?: boolean;
  primary?: boolean;
  big?: boolean;
  bigger?: boolean;
  isLoading?: boolean;
  fullOnWidth?: number;
}
export const BtnHover = css`
  ${easeIn};
  &:hover {
    ${easeOut};
    transform: translate3d(0, -3px, 0);
  }
  &:active {
    ${easeOutFast};
    transform: translate3d(0, 0, 0);
  }
`;
const BtnPrimary = css`
  background: ${RED};
  color: #fff;
  box-shadow: 0px 6px 12px rgba(230, 25, 23, 0.4);
  &:hover {
    box-shadow: 0px 6px 12px rgba(230, 25, 23, 0.5);
  }
  &:active {
    box-shadow: 0px 6px 12px rgba(230, 25, 23, 0.3);
  }
`;
const BtnBig = css`
  font-size: 17px;
  min-height: 50px;
`;
const BtnBigger = css`
  font-size: 27px;
  text-transform: uppercase;
  min-height: 70px;
  padding: 14px 45px;
`;
export const BtnLoading = css`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  position: relative;
  color: transparent;
  transition: 0s;

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    pointer-events: none;
    border-right: none;
    border-bottom: none;
    border-radius: 50%;
    margin: auto;
    animation: spin 0.75s linear 0s infinite;
    content: "";
  }
`;

const BtnDisabled = css`
  && {
    background: rgba(0, 0, 0, 0.26);
    box-shadow: 0px 6px 12px rgb(255, 255, 255);
    ${easeOut};
    transform: translate3d(0, 0, 0);

    &:hover {
      cursor: default;
    }
  }
`;

const ButtonStyles = css`
  min-height: 46px;
  min-width: 46px;
  font-size: 16px;
  padding: 14px;
  margin: 0;
  border: none;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
`;

const Btn = styled.button<BtnProps>`
  ${BtnHover};
  ${ButtonStyles};
  display: ${({ full }) => (full ? "block" : "inline-block")};
  width: ${({ full }) => (full ? "100%" : "auto")};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  ${({ primary }) => (primary ? BtnPrimary : undefined)};
  ${({ big }) => (big ? BtnBig : undefined)};
  ${({ bigger }) => (bigger ? BtnBigger : undefined)};
  ${({ isLoading }) => (isLoading ? BtnLoading : undefined)};
  ${({ disabled }) => (disabled ? BtnDisabled : undefined)};
  ${({ fullOnWidth }) =>
    fullOnWidth
      ? css`
          & {
            @media (max-width: ${fullOnWidth}px) {
              border-radius: 0;
              display: block;
              width: calc(100% + ${SIDE_SPACING * 2}vw);
              margin-left: -${SIDE_SPACING}vw;
              margin-right: -${SIDE_SPACING}vw;
            }
          }
        `
      : undefined};
`;

const BtnInsideLink = styled.span<BtnProps>`
  ${BtnHover};
  ${ButtonStyles};
  display: ${({ full }) => (full ? "block" : "inline-block")};
  width: ${({ full }) => (full ? "100%" : "auto")};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  ${({ primary }) => (primary ? BtnPrimary : undefined)};
  ${({ big }) => (big ? BtnBig : undefined)};
  ${({ bigger }) => (bigger ? BtnBigger : undefined)};
  ${({ isLoading }) => (isLoading ? BtnLoading : undefined)};
  ${({ disabled }) => (disabled ? BtnDisabled : undefined)};
  ${({ fullOnWidth }) =>
    fullOnWidth
      ? css`
          & {
            @media (max-width: ${fullOnWidth}px) {
              border-radius: 0;
              display: block;
              width: calc(100% + ${SIDE_SPACING * 2}vw);
              margin-left: -${SIDE_SPACING}vw;
              margin-right: -${SIDE_SPACING}vw;
            }
          }
        `
      : undefined};
`;

export default Button;
